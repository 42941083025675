<template>
  <div class="submit-form">
    <div v-if="!submitted">
      <div>
        <div class="mb-3">
          <label for="title" class="form-label">Titel/Anrede</label>
          <input v-model="user.title" type="text" class="form-control" id="title">
        </div>
        <div class="mb-3">
          <label for="forename" class="form-label">Vorname</label>
          <input v-model="user.forename" type="text" class="form-control" id="forename" aria-describedby="forenameHelp">
        </div>
        <div class="mb-3">
          <label for="surname" class="form-label">Nachname</label>
          <input v-model="user.surname" type="text" class="form-control" id="surname" aria-describedby="surnameHelp">
        </div>
        <div class="mb-3">
          <label for="email" class="form-label">E-Mail</label>
          <input v-model="user.email" type="text" class="form-control" id="email">
        </div>
        <div class="mb-3">
          <label for="username" class="form-label">Benutzername</label>
          <input v-model="user.username" type="text" class="form-control" id="username" aria-describedby="usernameHelp">
          <div id="usernameHelp" class="form-text">Standardmäßig alles Kleinbuchstaben.</div>
        </div>
        <div class="mb-3">
          <label for="password" class="form-label">Passwort</label>
          <input v-model="user.password" type="password" class="form-control" id="password" aria-describedby="passwordHelp">
          <div id="passwordHelp" class="form-text">Bitte wählen Sie ein ausreichend komplexes Passwort.</div>
        </div>
        <button @click="saveUser" type="submit" class="btn btn-primary text-white">Anlegen</button>
      </div>
    </div>

    <div v-else>
      <h4>Benutzer erfolgreich gespeichert!</h4>
      <button class="btn btn-success" @click="newUser">Weiteren hinzufügen</button>
    </div>

    <div v-if="error">
      <p class="text-danger">{{ error }}</p>
    </div>
  </div>
</template>

<script>
import UserService from "../services/user.service";

export default {
  name: "add-mandant",
  data() {
    return {
      error: null,
      submitted: false,
      user: {
        title: "",
        forename: "",
        surname: "",
        username: "",
        password: "",
      },
    };
  },
  methods: {
    saveUser() {
      let data = this.user;

      UserService.create(data)
          .then(response => {
            console.log(response.data);
            this.submitted = true;
          })
          .catch((error => this.error = error.response.data.message));
    },

    newUser() {
      this.submitted = false;
      this.error = false;
      this.mandant = {};
    }
  }
};
</script>

<style>
.submit-form {
  max-width: 300px;
  margin: auto;
}
</style>